@import "../../AppStyles";

.look-builder {

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    height: 100%;
    padding: 20px;

    &__head {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: wrap;
        padding: 0 10px;
        margin-bottom: 20px!important;
    }

    &__meta {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: wrap;
        padding: 0 10px;
        margin-bottom: 10px;
    }


    &__body {
        width: 100%;
        height: 650px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        padding: 10px 0;
    }

    &__foot {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        margin-top: auto;
        padding: 10px 10px 0 10px;
    }

    &__title {
        width: calc(100% - 370px);
        flex-basis: calc(100% - 370px);
        margin-right: auto!important;
    }

    &__presets {
        width: 350px;
        flex-basis: 350px;
    }

    &__column {
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: stretch;
        flex-wrap: wrap;

        &[data-column="left"] {
            width: 30%;
            flex-basis: 30%;
        }

        &[data-column="right"] {
            width: 70%;
            flex-basis: 70%;
        }
    }

    &__start {
        margin-right: auto;
    }

    &__total {
        font-size: 16px;
        font-weight: 500;
        color: $color-text-default;

        &:before {
            content: "Cтомость всего образа: ";
            font-weight: 300;
        }
    }


    .ant-select-clear {
        width: 16px;
        height: 16px;
        margin-top: -8px;
        opacity: 1!important;
        svg {
            width: 100%;
            height: 100%;
        }
    }
}