$color-text-default: #333;
$color-text-meta: #888;

$color-border-default: rgba(0, 0, 0, 0.05);
$color-border-dropzone: rgba(0, 0, 0, 0.3);
$color-border-dropzone-hover: rgba(0, 0, 0, 0.1);

$color-theme-active: #1890ff;
$color-theme-inactive: #ff4d4f;
$color-theme-pending: #fdab03;
$color-theme-succeed: green;
$color-theme-hovered: rgba(24,144,255, 0.1);

$color-set-designer-1: #D7D7D7;
$color-set-designer-2: #D1EEDA;
$color-set-designer-3: #B9DAE7;
$color-set-designer-4: #DAE7D6;
$color-set-designer-5: #DBBDD9;
$color-set-designer-6: #fae7eb;
$color-set-designer-7: #ecebbe;


[data-block-id="1"]:not(.ant-select-item) {
    background: $color-set-designer-1;
}

[data-block-id="2"]:not(.ant-select-item) {
    background: $color-set-designer-2;
}

[data-block-id="3"]:not(.ant-select-item) {
    background: $color-set-designer-3;
}

[data-block-id="4"]:not(.ant-select-item) {
    background: $color-set-designer-4;
}

[data-block-id="5"]:not(.ant-select-item) {
    background: $color-set-designer-5;
}

[data-block-id="6"]:not(.ant-select-item) {
    background: $color-set-designer-6;
}

[data-block-id="7"]:not(.ant-select-item) {
    background: $color-set-designer-7;
}


@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }
    @-ms-keyframes #{$animation-name} {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }
    @keyframes #{$animation-name} {
        @content;
    }
}