@import "../../AppStyles";

.look-preset-list {

    &__filters {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
    }

    &__filter-item {
        margin: 2px 20px 2px 0;
        padding: 2px 0 2px 16px;
        border-radius: 4px;
        font-size: 12px;
        position: relative;

        span {
            display: block;
            position: absolute;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            left: 0;
            top: 6px;
        }
    }


    &__default {
        display: block;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: $color-theme-active;
    }
}