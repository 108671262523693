@import "../../AppStyles";

.upload-dropzone {
    width: 100%;
    min-height: 150px;
    padding: 10px;
    background-color: $color-border-default;
    transition: background-color 0.3s, padding 0.3s;
    box-sizing: border-box;
    margin-bottom: 30px;
    position: relative;
    z-index: 10;

    &:hover {
        background-color: $color-border-dropzone-hover;
    }

    &[data-drag-over="true"] {
        background-color: $color-theme-hovered;
    }

    &__body {
        width: 100%;
        height: 100%;
        display: block;
        border: 2px dashed $color-border-dropzone;
        padding: 10px 20px;
        transition: border 0.3s;
        cursor: pointer;
    }


    &[data-drag-over="true"] &__body {
        border-style: solid;
        border-color: $color-theme-active;
    }

    &__empty {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 50px;
    }

    &__empty-icon {
        width: 30px;
        height: 30px;
    }

    &__empty-text {
        font-size: 20px;
        line-height: 2;
        color: $color-text-default;
    }

    &__empty-message {
        font-size: 15px;
        line-height: 1.5;
        color: $color-text-meta;
        opacity: 0.7;
    }

    &__files {
        display: none;
    }

}
