.products-list {
    &__head {
        display: flex;
        margin-bottom: 30px;
    }

    &__filters {
        flex: 0 0 calc(100% - 250px);
    }

    &__action {
        flex: 0 0 250px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
    }

    &__filter-list {
    }

    &__filter {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 15px 0;
    }

    &__filter-label {
        width: 10%;
        min-width: 100px;
    }

    &__filter-label-text {
        font-weight: 500;
    }

    &__filter-action {
        flex-grow: 1;

        .ant-picker {
            width: 100%;
        }
    }

    &__filter-action-selector {
        width: 300px;
    }

    &__body {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }

    &__body-archive {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
    }

    &__item {
        width: 100%;
        display: flex;
        flex-direction: row;
        padding: 10px 0 10px 40px;
        align-items: flex-start;
        justify-content: flex-start;
        position: relative;

        &:before {
            content: attr(data-position);
            opacity: 0.4;
            font-weight: 500;
            display: flex;
            width: 40px;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            padding: 20px 0 20px 5px;
        }
    }

    &__item + &__item {
        border-top: 1px solid rgba(0, 0, 0, 0.07);
    }

    &__item-card {
        width: 70%;
        flex: 0 0 70%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
    }

    &__item-actions {
        width: 30%;
        flex: 0 0 30%;
        display: flex;
        justify-content: center;
        align-self: center;
    }

    &__pagination {
        margin-top: 60px !important;
    }

    &__channel-select {
        width: 100%;
    }
}
