.packtum-layout-header {
    width: 100%;
    position: fixed;
    display: flex;
    z-index: 1000;
    align-content: center;
    justify-content: space-between;

    &__logo {
        color: #ffffff;
        font-size: 30px;
        line-height: 20px;
        margin-right: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        span {
            margin-top: 5px;
            line-height: 10px;
            font-size: 10px;
            padding-left: 1px;
            letter-spacing: 1.5px;
            opacity: 0.6;
        }
    }

    &__menu {
        margin: 0 15px;
    }

    &__search {
        flex: 1 0 auto;
        margin: 0 40px 0 15px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    &__informer {
        display: flex;
        align-items: center;
    }

    &__user {
        width: 200px;
        margin: 0 30px;
    }
}