@import "../../AppStyles";

.looks-list {

    &__body {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        counter-reset: product-item;
    }

    &__item {
        width: 100%;
        display: flex;
        flex-direction: row;
        padding: 10px 0 10px 40px;
        align-items: flex-start;
        justify-content: flex-start;
        position: relative;

        &:before {
            content: attr(data-position);
            opacity: 0.4;
            font-weight: 500;
            display: flex;
            width: 40px;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            padding: 20px 0 20px 5px;

        }
    }

    &__item + &__item {
        border-top: 1px solid rgba(0, 0, 0, 0.07);
    }

}