@import "../../AppStyles";

@mixin floating-element {
    border-radius: 10px;
    background: #fff;
}

.look-builder-block {
    width: 100%;
    border-radius: 10px;
    border: 4px solid #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;


    &[data-block-id="1"] {
        height: 50%;
        background: $color-set-designer-1;
    }

    &[data-block-id="2"] {
        height: 50%;
        background: $color-set-designer-2;
    }

    &[data-block-id="3"] {
        width: 60%;
        background: $color-set-designer-3;
    }

    &[data-block-id="4"] {
        width: 40%;
        background: $color-set-designer-4;
    }

    &[data-block-id="5"] {
        background: $color-set-designer-5;
    }

    &[data-block-id="6"] {
        width: 40%;
        background: $color-set-designer-6;
    }

    &[data-block-id="7"] {
        width: 60%;
        background: $color-set-designer-7;
    }

    &__select {
        position: absolute;
        top: 10px;
        left: 10px;
        padding: 0;
        max-width: 90%;
        z-index: 10;

        .ant-select {
            @include floating-element;
            width: 100%;
            height: 32px;
            overflow: hidden;
        }

        .ant-select-selector {
            outline: none;
            border: none !important;
        }

        .ant-select,
        .ant-select-disabled.ant-select:not(.ant-select-customize-input) {
            background: none !important;
            border-radius: 0 !important;

            font-size: 16px;
            font-weight: 400;

            .ant-select-selector {
                padding-right: 15px!important;
                background: none !important;
                border-radius: 0 !important;
            }
            .ant-select-selection-item {
                text-decoration: underline;
                text-decoration-thickness: 1.2px;
            }
        }
        .ant-select-clear {
            width: 10px!important;
            height: 10px!important;
            border-radius: 50%!important;
            display: block!important;
            right: 0!important;
            margin-top: -3.5px!important;
            background: none!important;

            svg,
            .look-builder__clear {
                display: block!important;
                fill: $color-text-default!important;
            }
        }
    }

    &__canvas {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
        z-index: 5;
    }

    &__image {
        position: absolute;
        top: 50%;
        left: 50%;
        height: 76%;
        width: 80%;
        max-width: 80%;
        transform: translate(-50%, -50%);
        z-index: 3;
        text-align: center;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        img {
            @include floating-element;
            max-width: 100%;
            max-height: 100%;
            display: block;
            margin: 0 auto;
        }
    }

    &__actionbar {
        width: auto;
        position: absolute;
        left: 10px;
        bottom: 10px;
        z-index: 10;
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        align-items: center;
    }

    &__price {
        height: 22.5px;
        margin-left: 8px;
        font-weight: 500;
    }

    &__link,
    &__lock {
        width: 24px;
        height: 24px;
        padding: 6px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-align: center;
        z-index: 10;
        cursor: pointer;

        svg {
            display: block;
            width: 100%;
            height: 100%;
        }

        &:hover {

        }
    }

    &__lock {
        svg {
            width: 13px;
            height: 13px;
        }
        transform: translateY(0.5px);
    }



    //&__lock {
        //width: 30px;
        //height: 30px;
        //display: flex;
        //flex-direction: row;
        //justify-content: center;
        //align-items: center;
        //text-align: center;
        //z-index: 10;
        //cursor: pointer;
    //}
}

.ant-select-dropdown.ant-select-dropdown-placement-bottomLeft  {
    width: 200px!important;
}