.product-draft-card {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 10px 20px;
    align-items: flex-start;
    justify-content: flex-start;

    &__body {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }

    &__images {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-self: flex-start;
        margin-bottom: 20px;
        align-items: flex-start;
        justify-content: flex-start;

    }

    &__image {
        width: 24%;
        flex: 0 0 24%;
        padding-top: 32.5%;
        margin-right: 1%;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: #dedede;
    }

    [data-source="vagabond"] &__image {
        background-position: 38% 0;
    }

    &__actions {
        width: 0;
        height: 100%;
        min-height: 70px;
        position: relative;
    }

    &__remove,
    &__edit {
        position: absolute;
        width: 35px;
        height: 35px;
        border: none;
        background-color: transparent;
        transition: background-color 0.3s;
        cursor: pointer;
        padding-top: 5px;

        &:hover {
            background-color: #efefef;
        }

        svg {
            width: 20px;
            height: 20px;
            fill: #000;
            opacity: 0.6;
        }
    }
    &__remove {
        top: 0;
        left: 0;

    }
    &__edit {
        top: 37px;
        left: 0;
    }
    &__info {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        align-items: flex-start;
        justify-content: flex-start;
        max-width: 50%;
    }

    &__title {
        margin-bottom: 4px;
        color: rgba(0, 0, 0, .85);
        font-size: 14px;
        line-height: 1.5715;
        font-weight: 500;
    }

    &__price {
        color: rgba(0, 0, 0, .45);
        font-size: 14px;
        line-height: 1.5715;
    }

    &__url {
        max-width: 100%;
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: #1890ff;
        padding-right: 15px;
        margin-bottom: 20px;
    }

    &__store {
        color: rgba(0, 0, 0, .45);
        font-size: 14px;
        line-height: 1.5715;
    }

    &__filters {
        width: 40%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-self: center;
        justify-content: flex-start;
    }

    &__filters-item {
        background: #fafafa !important;
        border: 1px solid #d9d9d9 !important;
        color: rgba(0, 0, 0, 0.85) !important;
        margin-bottom: 8px !important;

        &.ant-tag-checkable-checked {
            color: #fff !important;
            background-color: #1890ff !important;
            border-color: #1890ff !important;
        }

        &.ant-tag-checkable:active {
            background-color: #096dd9 !important;
        }

        &.ant-tag-checkable:active,
        .ant-tag-checkable-checked {
            color: #fff !important;
        }
    }
}