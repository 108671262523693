@import 'src/AppStyles';

.filters-list {
    &__search {
        margin-bottom: 40px;
    }

    &__items {
        display: flex;
        overflow-x: auto;
    }

    &__items-row {
        display: flex;
        flex-direction: row;
        padding: 10px 0 10px 15px;
        position: relative;

        &:before {
            content: '';
            width: 2px;
            position: absolute;
            top: 5px;
            left: 0;
            bottom: 5px;
            background: #dadada;
        }

        &[data-channel-active='true']:before {
            background: $color-theme-active;
        }

        &[data-channel-active='false']:before {
            background: $color-theme-inactive;
        }
    }

    &__items-row + &__items-row {
        border-top: 1px solid rgba(0, 0, 0, 0.07);
    }

    &__items-card {
        width: 70%;
        flex: 0 0 70%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        font-weight: bold;
        transition: opacity 0.3s;

        &[data-channel-active='false'] {
            opacity: 0.4;
        }
    }

    &__items-actions {
        width: 30%;
        flex: 0 0 30%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.filter-list-item {
    &__status {
        display: block;
        width: 10px;
        height: 10px;
        margin: 0 auto;
        border-radius: 50%;
        background-color: #dedede;

        &[data-is-active='true'] {
            background-color: $color-theme-active;
        }
        &[data-is-active='false'] {
            background-color: $color-theme-inactive;
        }
    }
}
