@import "src/AppStyles";

.channel-card {
    display: flex;
    height: 100%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    &[data-channel-active="false"] {
        opacity: 0.5;
    }

    &__head {
        padding-top: 5px;
        margin-right: 15px;
    }

    &__body {
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    &__id {
        display: block;
        font-size: 12px;
        opacity: 0.7;
        margin-top: 10px;


        &:before {
            content: "ID: ";
        }
    }

    &__title {
        color: rgba(0, 0, 0, .85);
        font-size: 14px;
        line-height: 1.5;
        font-weight: 500;
    }

    &__description {
        font-size: 14px;
        line-height: 1.5;
        opacity: 0.6;
        margin-top: 2px;
    }
}
