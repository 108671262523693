html, body, #root, #not-mobile-yet {
    height: 100%;
}

@keyframes pulse {
    0% { transform: scale(1); }
    5% { transform: scale(1.3); }
    10% { transform: scale(1); }
    15% { transform: scale(1.3); }
    20% { transform: scale(1); }
    100% { transform: scale(1); }
}


#not-mobile-yet {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

#not-mobile-yet .heart {
    pointer-events: none;
    background-color: black;
    display: inline-block;
    width: 40px;
    height: 40px;
    margin: 0 10px 60px 10px;
    position: relative;
    top: 0;
    transform: rotate(-45deg);
}

#not-mobile-yet .heart:before,
#not-mobile-yet .heart:after {
    content: "";
    width: 40px;
    height: 40px;
    background-color: black;
    border-radius: 50%;
    position: absolute;
}

#not-mobile-yet .heart:before {
    top: -20px;
    left: 0;
}

#not-mobile-yet .heart:after {
    left: 20px;
    top: 0;
}

#not-mobile-yet .heart-text {
    font-size: 18px;
    max-width: 270px;
    opacity: 1!important;
}

.heart-shape-box {
    animation: pulse 10s ease infinite;
    transform-origin: top;
}



@media not all and (orientation: portrait) {
    #root {
    }
    #not-mobile-yet {
        display: none;
    }
}

@media screen and (max-width: 1024px) {
    #root {
        display: none;
    }
    #not-mobile-yet {
        display: flex;
    }
}