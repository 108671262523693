@import "../../AppStyles";

.look-card-archive {
    $height: 150px;

    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 5px 15px;

    &__head {
        margin-bottom: 10px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        position: relative;
        gap: 15px;
    }

    &__image {
        width: 150px;
        height: 200px;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: #dedede;
    }
}