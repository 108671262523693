.channel-view {
    height: 100%;
    padding: 24px;
    display: flex;
    flex-direction: column;

    &__head {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        padding-left: 80px;
        min-height: 60px;
        position: relative;
        margin-bottom: 40px;
    }

    &__body {
    }

    &__image {
        position: absolute;
        top: 0;
        left: 0;
    }

    &__actions {
        margin-top: auto;
        padding-top: 20px;
    }
}
