.scrapper-section {

    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 15px 0;
    transition: background-color 0.3s;

    &:hover {
        background-color: #f6f6f6;
    }

    &--to-telegram {
        background: #e6f7ff;

        &:hover {
            background-color: #d5f5ff;
        }
    }

    &__head {
        flex: 0 0 20%;
        padding: 0 15px;
    }

    &__body {
        flex: 1 0 80%;
        padding: 0 15px;
    }

    &__gallery {
        position: relative;
        z-index: 10;
    }

    &__label {
        font-weight: 500;

        &.required:after {
            content: " *";
            color: red;
            font-size: 20px;
            line-height: 16px;
        }
    }


    &__link {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }

    &__link-text {
        font-size: 14px;
        color: #40a9ff;
        text-decoration: underline;
    }

    &__link-copy {
        margin-right: 20px;
        cursor: pointer;
        filter: grayscale(1);
        transition: filter 0.3s;
        -webkit-transition: -webkit-filter 300ms linear;

        &:hover {
            filter: grayscale(0);
        }

        svg {
            width: 20px;
            height: 20px;
        }
    }
}

.scrapper-input {

    display: flex;
    flex-direction: row;

    &__field {
    }

    &__button {
        width: 150px;
        margin-left: 15px;
    }
}

.scrapper-actions {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.scrapper {
    &__store {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
    }

    &__store-logo {
        max-width: 80%;
        max-height: 64px;
    }
}
