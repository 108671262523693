@import "../../../../AppStyles";

.auth-simple {
    width: 450px;


    &__login {
        margin-bottom: 10px;
    }

    &__input {

    }

    &__label {
        color: $color-text-meta;
    }

    &__field {
        margin-top: 5px;
        letter-spacing: 5px;
    }

    &__message {
        color: $color-text-meta;
    }

    &__resend {
        color: $color-theme-active;
        cursor: pointer;
        &:hover {
            text-decoration: none;
        }
    }

    &__actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__timer {
        position: absolute;
        top: 50%;
        right: 15px;
        line-height: 26px;
        color: $color-text-meta;
        &[data-timeout="true"] {
            color: $color-theme-inactive
        }
    }
}