.channel-select-modal {

    &__limits {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 20px 0 0 0;
    }

    &__limits-head {
        width: 100%;
        margin-bottom: 10px;
    }

    &__limits-body {
        width: 100%;
        display: flex;

        justify-content: space-between;
        align-items: center;
    }

    &__limits-separator {
        padding: 0 10px;
    }

    &__limits-from {

    }

    &__limits-to {

    }

}