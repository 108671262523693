@import "src/AppStyles";

.channels-list {

    &__items {}

    &__items-row {
        display: flex;
        flex-direction: row;
        padding: 10px 0 10px 15px;
        position: relative;

        &:before {
            content: "";
            width: 2px;
            position: absolute;
            top: 5px;
            left: 0;
            bottom: 5px;
            background: #dadada;
        }

        &[data-channel-active="true"]:before {
            background: $color-theme-active;
        }

        &[data-channel-active="false"]:before {
            background: $color-theme-inactive;
        }

    }

    &__items-row + &__items-row {
        border-top: 1px solid rgba(0, 0, 0, 0.07);
    }

    &__items-card {
        width: 70%;
        flex: 0 0 70%;
    }

    &__items-actions {
        width: 30%;
        flex: 0 0 30%;
        display: flex;
        justify-content: center;
        align-items: center;
    }


}