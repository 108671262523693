.topbar-user {
    width: 100%;

    &__title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    &__name {
        font-size: 16px;
        line-height: 25px;
        color: #ffffff;
    }

    &__role {
        font-size: 12px;
        line-height: 15px;
        color: #ffffff;
        opacity: 0.4;
    }

    &__dropdown {
        border-color: transparent;
        -webkit-box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09) !important;
        box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09)!important;
    }
}