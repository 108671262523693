@import '../../AppStyles';

.filter-editor {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    &__body {
        width: 100%;
        flex: 1 0 auto;
        padding: 24px;
        overflow: auto;
        height: calc(100% - 50px);
    }

    &__foot {
        width: 100%;
        height: 50px;
        flex: 0 0 auto;
        padding: 0 24px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
    }

    &__row {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 30px;
    }

    &__label {
        margin-bottom: 10px;
        font-weight: 500;
    }

    &__look {
        width: 100%;
        height: 140px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
    }

    &__look-column {
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: stretch;
        flex-wrap: wrap;

        &[data-column='left'] {
            width: 30%;
            flex-basis: 30%;
        }

        &[data-column='right'] {
            width: 70%;
            flex-basis: 70%;
        }
    }

    &__look-block {
        width: 100%;
        border-radius: 10px;
        border: 2px solid #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;

        &[data-block-id='1'] {
            height: 50%;
            background: $color-set-designer-1;
        }

        &[data-block-id='2'] {
            height: 50%;
            background: $color-set-designer-2;
        }

        &[data-block-id='3'] {
            width: 60%;
            background: $color-set-designer-3;
        }

        &[data-block-id='4'] {
            width: 40%;
            background: $color-set-designer-4;
        }

        &[data-block-id='5'] {
            background: $color-set-designer-5;
        }

        &[data-block-id='6'] {
            width: 40%;
            background: $color-set-designer-6;
        }

        &[data-block-id='7'] {
            width: 60%;
            background: $color-set-designer-7;
        }
    }

    &__items-wrap {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    &__tree {
        width: 100%;
        height: 302px;
        border: 1px solid #d9d9d9;

        transition: all 0.3s;
        &[data-is-collapsed='false'] {
            opacity: 0.0001;
            height: 0 !important;
            pointer-events: none;
            padding: 0;
        }
    }

    &__visible-arrow {
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        transform-origin: center center;
        transition: transform 0.6s;

        &[data-is-collapsed='false'] {
            transform: rotate(180deg);
        }
    }
}
