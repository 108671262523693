.drawer-panel-footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    &__cancel,
    &__submit {
        margin-left: 15px;
    }
}