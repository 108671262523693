.login-layout {

    $color-bg: #e6f7ff;
    $color-accent: #1890ff;
    $color-panel: #001529;

    height: 100%;

    &__body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &__title {
        text-transform: uppercase;
    }

    &__switch {
        margin: 5px 0 20px 0;
    }

    &__switch-item {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin: 0 8px;
        svg {
            width: 18px;
            height: 18px;
        }
        &[data-active="true"] {
            color: #40a9ff;
            background: #fff;
            border-color: #40a9ff;
        }
    }


    &__divider {
        margin: 30px 0 !important;
        opacity: 0.6;
    }

    &__alternatives {
        width: 450px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

    }

    &__auth-telegram {
        display: block;
        height: 40px;
    }

    &__auth-password {
        padding: 0;
    }


    &__foot {
        background: $color-panel;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }


}