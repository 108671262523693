.product-card-archive {
    width: 100%;
    padding: 10px 20px;

    &__body {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-self: center;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        position: relative;
        margin-bottom: 20px;
    }

    &__link {
        width: 100%;
    }

    &__image {
        width: 100%;
        padding-top: 130%;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: #dedede;
    }

    [data-source='vagabond'] &__image {
        background-position: 38% 0;
    }

    &__info {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: white;
        padding: 30px 10px;
        transition: visibility 0s, opacity 0.1s linear;
        opacity: 0.00001;
    }

    &__info-wrap {
        position: relative;
    }

    &__body:hover &__info {
        opacity: 1;
    }

    &__actions {
        position: absolute;
        right: -10px;
        top: -30px;
        display: flex;
    }

    &__remove,
    &__edit {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 35px;
        height: 35px;
        border: none;
        background-color: transparent;
        transition: background-color 0.3s;
        cursor: pointer;

        &:hover {
            background-color: #efefef;
        }

        svg {
            width: 20px;
            height: 20px;
            fill: #000;
            opacity: 0.6;
        }
    }

    &__remove {
        top: 0;
        left: 0;
    }

    &__edit {
        top: 37px;
        left: 0;
    }

    &__title {
        margin-bottom: 5px;
        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;
        line-height: 1.5715;
        font-weight: 500;
    }

    &__price {
        margin-bottom: 5px;
        color: rgba(0, 0, 0, 0.45);
        font-size: 14px;
        line-height: 1.5715;
    }

    &__url {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__url-link {
        color: #1890ff;
        max-width: 100%;
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-right: 5px;
    }

    &__url-icon {
        color: rgba(0, 0, 0, 0.85);
        transition: color 0.3s;
        padding: 0 5px;
        cursor: pointer;

        &:hover {
            color: #1890ff;
        }
    }

    &__store {
        color: rgba(0, 0, 0, 0.45);
        font-size: 14px;
        line-height: 1.5715;
    }
}
