@import "../../AppStyles";

.look-card {
    $height: 150px;

    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 5px 15px;

    &__head {
        margin-bottom: 10px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        position: relative;
    }

    &__info {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        align-items: flex-start;
        justify-content: flex-start;
    }

    &__image {
        height: $height;
        margin: 0 10px 5px 0;
    }

    &__title {
        margin-bottom: 4px;
        color: rgba(0, 0, 0, .85);
        font-size: 14px;
        line-height: 1.5715;
        font-weight: 500;
    }

    &__price {
        color: rgba(0, 0, 0, .45);
        font-size: 14px;
        line-height: 1.5715;
    }

    &__tags {
        color: rgba(0, 0, 0, .45);
        font-size: 14px;
        line-height: 1.5715;
    }

    &__filters {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        padding: 2px 0;
    }

    &__filter-item {
        margin-right: 10px;
        margin-bottom: 10px;
        padding: 3px 10px;
        border-radius: 4px;
        color: $color-text-default;
    }
}