@import "../../AppStyles";


@include keyframes('cssProgressActive') {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 35px 35px;
    }
}