.tag-checkable {
    background: #fafafa !important;
    border: 1px solid #d9d9d9 !important;
    color: rgba(0, 0, 0, 0.85) !important;
    margin-bottom: 8px !important;
    user-select: none;

    &.ant-tag-checkable-checked {
        color: #fff !important;
        background-color: #1890ff !important;
        border-color: #1890ff !important;
    }

    &.ant-tag-checkable:active {
        background-color: #096dd9 !important;
    }

    &.ant-tag-checkable:active,
    .ant-tag-checkable-checked {
        color: #fff !important;
    }
}