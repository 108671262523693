@import "../../AppStyles";

.product-editor {

    &__section {

        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;

        & + & {
            padding-top: 10px;
            margin-bottom: 10px;
        }
    }

    &__section-head {
        width: 20%;
        min-width: 200px;
        flex: 0 0 20%;
    }

    &__section-body {
        flex-grow: 1;
    }

    &__label {

        &.required:after {
            content: " *";
            color: red;
            font-size: 20px;
            line-height: 16px;
        }
    }

    &__input {
        width: 100%;
    }

    &__select {
        width: 100%;
    }

    &__tags {
        width: 100%;
        display: flex;
        overflow-x: auto;
    }

    &__gallery {
        min-height: 100px;
        background-color: $color-set-designer-3;
    }

    &__price {
        width: 300px;
    }

    &__source {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        position: relative;
        z-index: 1;
    }

    &__source-select {
        margin-right: 20px;
        width: 300px;
    }

    &__source-input {
        flex-grow: 1;
    }


    &__actions {
        padding: 40px 0 10px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        & > * {
            margin-right: 20px;
        }
    }


}
